import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import $ from 'jquery';
import { NotificationService } from './services/notification.service';
import { firebase } from '@firebase/app';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private menu: MenuController,
    private storage: Storage,
    private notification: NotificationService,
  ) {
    this.initializeApp();
    this.router.navigateByUrl('/loader');

  }


  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  changeMenu(index) {
    $('.each-menu-item').removeClass('menu-active');
    // $('.each-menu-item')[index].addClass('menu-active');
    document.getElementsByClassName('each-menu-item')[index].classList.add('menu-active');
    this.menu.close();
  }

  initializeApp() {

    this.platform.ready().then(() => {
      console.log("navigate here");
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // INTIALLY LOADING PAGE WILL BE VISIBLE
    });

  }

  logout() {
    this.storage.clear();
    console.log("logout");
    location.reload();
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      await this.notification.requestPermission();
    });
  }

  async ngOnInit() {
    firebase.initializeApp(environment.firebase);
    await this.notification.init();
  }
}
