import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StoredataService } from '../services/storedata.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(null);//AVOID ANY PAGE TO LOAD INITIALLY
  constructor(private router: Router, private storedataService: StoredataService) {
    // GET USER DATA STORED IN DEVICE
    this.checkuser();
  }

  checkuser() {
    this.storedataService.returnlocaldata().then((data) => {
      // console.log(data);
      if (data) {
        //NUTRITIONIST LOGGED IN BEFORE 
        this.storedataService.nutritionistdata = data;
        this.authenticationState.next(true);
      } else {
        this.authenticationState.next(false);
      }

    });

  }


}
