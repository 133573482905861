import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagepathPipe } from './imagepath.pipe';
import { DateformatPipe } from './dateformat.pipe';



@NgModule({
  declarations: [ImagepathPipe, DateformatPipe],
  imports: [
    CommonModule
  ],
  exports: [ImagepathPipe,DateformatPipe]
})
export class PipeModule { }
