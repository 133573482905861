import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/auth-guard.service";



const routes: Routes = [
  { path: '', redirectTo: 'loader', pathMatch: 'full' },
  { path: 'home/:pageno', canActivate: [AuthGuardService], loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'user/:id', canActivate: [AuthGuardService], loadChildren: './user/user.module#UserPageModule' },
  { path: 'dietmaker/:id/:newid/:action', canActivate: [AuthGuardService], loadChildren: './dietmaker/dietmaker.module#DietmakerPageModule' },
  { path: 'dietselection/:id/:action', canActivate: [AuthGuardService], loadChildren: './dietselection/dietselection.module#DietselectionPageModule' },
  { path: 'otp', loadChildren: './otp/otp.module#OtpPageModule' },
  { path: 'action/:id', canActivate: [AuthGuardService], loadChildren: './action/action.module#ActionPageModule' },
  { path: 'userdiets', canActivate: [AuthGuardService], loadChildren: './userdiets/userdiets.module#UserdietsPageModule' },
  { path: 'diets/:dietid', canActivate: [AuthGuardService], loadChildren: './diets/diets.module#DietsPageModule' },
  { path: 'loader', loadChildren: './loader/loader.module#LoaderPageModule' },
  { path: 'userinfo/:id', loadChildren: './userinfo/userinfo.module#UserinfoPageModule' },
  { path: 'usermetricdetails/:id', loadChildren: './usermetricdetails/usermetricdetails.module#UsermetricdetailsPageModule' },
  { path: 'updatemetric', loadChildren: './updatemetric/updatemetric.module#UpdatemetricPageModule' },
  { path: 'dietshow', loadChildren: './dietshow/dietshow.module#DietshowPageModule' },
  { path: 'users/:pageno', loadChildren: './users/users.module#UsersPageModule' },
  { path: 'adduser', loadChildren: './adduser/adduser.module#AdduserPageModule' },
  { path: 'posts', loadChildren: './posts/posts.module#PostsPageModule' },
  { path: 'post-listing', loadChildren: './post-listing/post-listing.module#PostListingPageModule' },
  { path: 'appointment-listing', loadChildren: './appointment-listing/appointment-listing.module#AppointmentListingPageModule' },
  { path: 'appointments', loadChildren: './appointments/appointments.module#AppointmentsPageModule' },
  { path: 'registered-users', loadChildren: './registered-users/registered-users.module#RegisteredUsersPageModule' },





];
console.log("Routes called");

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }