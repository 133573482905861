import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateformat'
})
export class DateformatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    var date = new Date(value);

    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    if (dd == 'NaN') {
      return '00-00-0000';
    } else {
      return dd + '-' + mm + '-' + yyyy;
    }

  }

}
