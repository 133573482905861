import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagepath'
})
export class ImagepathPipe implements PipeTransform {
  url: string = '';
  constructor() {
    // if (window.location.host == 'projectnow.pixoloproductions.com') {
    //   this.url = 'https://www.pixoloproductions.com/projectnow/rest/';
    // } else {
    //   this.url = 'https://www.pixoloproductions.com/projectnow/testapi/';
    // }

    if (window.location.host == 'dietmaker.projectnow.in') {
      this.url = "https://api.projectnow.in/";
    } else {
      this.url = "https://api.projectnow.in/stg/";
    }

  }
  transform(value: string, arg): any {
    if (arg) {
      return this.url + 'uploads/' + arg + "/" + value;
    } else {
      return this.url + 'uploads/' + value;
    }

  }

}
