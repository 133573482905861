import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, public authservice: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.authservice.authenticationState.value;
  }
}
