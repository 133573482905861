import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class StoredataService {

  users: any;
  nutritionistdata: any;
  metricdata: any;
  previousurl: any;
  userEthnicity:any = [];

  constructor(private storage: Storage) { }


  setNutritionist(nutritionist) {
    this.nutritionistdata = nutritionist;
    this.storage.set('nutritionistdata', this.nutritionistdata);

  }

  returnlocaldata() {
    return this.storage.get('nutritionistdata');

  }
}
